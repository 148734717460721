import React, { useState } from 'react'
import { useAuthentication } from './contexts/authContext';
import { DashboardBrowsingIcon, ArrowRight01Icon, ArrowDown02Icon, Menu03Icon, Cancel01Icon, Home01Icon, NoteEditIcon, NotebookIcon, MessageOutgoing02Icon, Notification02Icon, ArrowDown01Icon } from 'hugeicons-react'
import { Link } from 'react-router-dom'
import "../css/header/header.css"
import { useNavigate } from 'react-router-dom';

const AuthenticatedHeader = () => {
  return (
    <div className="authenticated-header">
      <div className="navigation">
        <div className="links">
          <Link className='link active'>
            <div className="icon">
              <Home01Icon
                size={24}
                variant={"stroke"}
              />
            </div>
            Dashboard
          </Link>
          <Link className='link'>
            <div className="icon">
              <NoteEditIcon
                size={24}
                variant={"stroke"}
              /></div>
            Courses
          </Link>
          <Link to={'/dashboard/cohorts/'} className='link' >
            <div className="icon">
              <NotebookIcon
                size={24}
                variant={"stroke"}
              />
            </div>
            Cohorts
          </Link>
        </div>

        <div className="search">
          {/* <input type="search" name="headerSearch" id=""  className=''/> */}

        </div>

        <div className="profile-links">
          <div className="icon">
            <MessageOutgoing02Icon
              size={24}
              variant={"stroke"}
            />
          </div>
          <div className="notification">
            <Notification02Icon
              size={24}
              variant={"stroke"}
            />
          </div>
          <div className="profile">
            <img src="" alt="" className="profile" />
            <div className="icon">
              <ArrowDown01Icon
                size={24}
                variant={"stroke"}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const Header = () => {
  const { isAuth } = useAuthentication();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const handleClick = (sectionId) => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }


    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };

  return isAuth ? <AuthenticatedHeader /> : (
    <nav className="header">
      <div className={`nav ${menuIsOpen ? 'open' : ''}`}>
        <div className='branding'>
          <Link to={"/"}>
            <img className='logo' src={"/images/CSR Logo white.svg"} alt="" /></Link>
        </div>
        <div className='text-button'>
          <div className='nav-content'>
            <ul>

              <Link className='link' to="/" onClick={handleClick}>
                <li>Home</li>
              </Link>
              <a href="/#about" className='link' onClick={handleClick}>
                <li>About Us</li>
              </a>

              <a href="/#services" className='link' onClick={handleClick}>
                <li>Trainings</li>
              </a>

              <Link className='link' to="/programs/" onClick={handleClick}>
                <li>Programs</li>
              </Link>
              <Link className='link' to="/partnership/" onClick={handleClick}>
                <li>Partnership</li>
              </Link>
              <a href={"/trainings/cohort/4/apply/"} className="button primary-button" >
                <span className="button-text" >Apply now</span>
                <div className="icon">
                  <ArrowRight01Icon
                    size={24}
                    variant={"stroke"}
                  />
                </div>
              </a>

            </ul>

            <div className="menu-icon">
              <div onClick={toggleMenu} className="open-menu">
                <Menu03Icon
                  size={32}
                  color={"#000000"}
                  variant={"stroke"}
                />
              </div>

              <div onClick={toggleMenu} className="close-menu">
                <Cancel01Icon
                  size={32}
                  color={"#000000"}
                  variant={"stroke"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </nav>

  )
}

export default Header
